import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import RichText from '~components/RichText'
import Image from '~components/Image'
import Seo from '~components/Seo'
import Section from '~components/Section'
import DataViewer from '~utils/DataViewer'
import SanityLink from '~components/SanityLink'
import { mobile, tablet, PageHeader, Headline, Subtitle } from '~styles/global'

const Page = ({ data }) => {
	const { page } = data
	return(
		<>
			<Seo 
				title={page.title}
				metaTitle={page.seo?.metaTitle}
				description={page.seo?.metaDescription}
				image={page.seo?.socialImage}
			/>
			<PageHeader>
				<Subtitle>{page.title}</Subtitle>
			</PageHeader>
			<Section>
				<Text>
					<RichText content={page.text}/>
				</Text>
			</Section>
			<DataViewer data={page} name="page"/>
		</>
	)
}

Page.propTypes = {
	data: PropTypes.object,
}


const Text = styled.div`
	max-width: 672px;
	width: 100%;
	margin: 0 auto;
	grid-column: 2/12;
	margin-Bottom: var(--xxxl);
	p{
		margin-bottom: var(--m);
		font-size: 21px;
	}
	h4{
		font-family: 'Masqualero', serif;
		font-size: 26px;
		line-height: 1.25;
		letter-spacing: -0.17px;
		text-transform: none;
		${tablet}{
			font-size: 22px;
		}
	}
	ol, ul{
		margin-top: var(--xxl);
		margin-bottom: var(--xl);
	}
	li {
		font-size: 21px;
		margin-left: 1.4em;
    padding-left: 0.2em;
	}

	if (style === 'h4') {
				return <p>{props.children}</p>
			}
			if (style === 'normal') {
				return <p className='small'>{props.children}</p>
			}

	${mobile}{
		grid-column: span 12;
	}
`

export const query = graphql`
  query PageQuery($slug: String) {
    page: sanityPage(slug: {current: {eq: $slug}}) {
      title
			text: _rawText(resolveReferences: {maxDepth: 8})
    }
  }
`

export default Page